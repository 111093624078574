import { Box } from '@chakra-ui/react';
import React from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { Heading } from '@prasanna-transcend/code-quick-components';
import AccountForm from './AccountForm';
import UserManagementProvider from '../../providers/UserManagementProvider';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';

const AccountContainer = props => {
  
  return (
    <>
      <CQContainer py={30} px={30} pb={20} mx="10%">
        <Heading variant="h4"
              style={{ fontFamily: FONT_FAMILY.ptSans }} mb={4}>
          My Account
        </Heading>
        <Box
          bg="white"
          p={4}
          pb={20}
          w="100%"
          h="150%"
          borderRadius="md"
          ml={0}
        >
          <AccountForm />
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(UserManagementProvider, AccountContainer);
