import React, { useState } from 'react';
import { MinusIcon } from '@chakra-ui/icons';
import {
  Box,
  useColorModeValue,
  HStack,
  Flex,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import { TextInput, useBoolean } from '@prasanna-transcend/code-quick-components';
import ContextMenu from '../../../components/ContextMenu';
import AppColors from '../../../constants/AppColors';
import {
  LeftTriangleIcon,
  PlusIcon,
  RightTriangleIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';

export default function AuditSheetFooter({ sheets, activeSheetIndex, onSheetChange, addNewSheet, changeSheetName, onLeftNavigate, onRightNavigate, onDeleteSheet, onZoom }) {
  const [isContextMenu, icmState] = useBoolean(false)
  const [isRename, irState] = useBoolean(false)
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(null)
  const [width, setWidth] = useState(0)


  function handleSheetSelection(sheetIndex) {
    if (onSheetChange) {
      onSheetChange(sheetIndex);
    }
  }

  const [sliderValue, setSliderValue] = useState(50);

  const CustomMenu = () => (
    <Box className="menu">
      <Text fontSize={'xs'} onClick={
        async () => {
          await onDeleteSheet(selectedSheetIndex)
          icmState.off()
        }
      }>Delete</Text>
      <Text fontSize={'xs'} mt={2} onClick={() => {
        irState.on()
        icmState.off()
      }
      }>Rename</Text>
    </Box>
  );

  return (
    <Box w={'100%'} style={{zIndex:10}}>
      <Flex
        bg={AppColors.primary}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        width={'100%'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex flex={{ base: 1 }} alignItems={'center'}>
          <LeftTriangleIcon
            style={{
              marginLeft: 20,
              width: 10,
              height: 10,
              cursor: 'pointer',
              display: 'inline-block',
            }}
            onClick={() => onLeftNavigate()}
          />
          <RightTriangleIcon
            style={{
              marginLeft: 10,
              marginRight: 20,
              width: 10,
              height: 10,
              cursor: 'pointer',
              display: 'inline-block',
            }}
            onClick={() => onRightNavigate()}
          />
          {
            sheets.length ? sheets.map((data, index) => {
              return <Box
                position={'relative'}
                onContextMenu={() => {
                  setSelectedSheetIndex(index)
                  setWidth(data.length)
                  icmState.on()
                }}
                onClick={() => {
                  if (!isContextMenu) {
                    handleSheetSelection(index)
                  }
                }}
                cursor={'pointer'}
                key={`${index}-sheets`}
                bg={index === activeSheetIndex ? 'white' : 'transparent'}
                p={3}
                borderBottomRadius={'xl'}
              >
                {(isContextMenu && index === selectedSheetIndex) ? <ContextMenu menu={() => <CustomMenu />} /> : null}
                {(isRename && index === selectedSheetIndex) ? <TextInput
                  fontSize='sm'
                  id={`${index}-sheet-name`}
                  name="sheet"
                  defaultValue={data}
                  value={data}
                  onChange={async e => {
                    await changeSheetName(index, e.target.value)
                    setWidth(e.target.value.length)
                  }}
                  textAlign="center"
                  style={{ border: 'transparent', width: width <= 3 ? '5ch' : width + 3 + 'ch', paddingInline: 1 }}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      e.stopPropagation();
                      e.target.focus();
                    } else {
                      irState.off()
                    }
                  }}
                />
                  : <Text fontFamily={FONT_FAMILY.ptSans} color={index === activeSheetIndex ? AppColors.secondary : 'white'}
                    fontSize='sm'>
                    {data}
                  </Text>}
              </Box>
            }) : null
          }
          <Text fontFamily={FONT_FAMILY.ptSans} color={'white'} fontSize='sm'>
            |
          </Text>
          <PlusIcon style={{ marginLeft: 15, marginRight: 15, width: 10, height: 10, display: 'inline-block', cursor: 'pointer' }} onClick={async () => await addNewSheet()} />
          <Text fontFamily={FONT_FAMILY.ptSans} color={'white'} fontSize='sm'>
            |
          </Text>
        </Flex >
        <HStack alignItems={'center'} gap={1} mr={10}>
          <MinusIcon style={{ width: 10, height: 10, display: 'inline-block',cursor:"pointer" }} onClick={()=> {setSliderValue(sliderValue-5)
            onZoom(sliderValue-5)}}/>
          <Slider
            justifyContent={'end'}
            aria-label="slider-ex-1"
            colorScheme="gray"
            defaultValue={50}
            width={100}
            value={sliderValue}
            min={0}
            max={100}
            onChange={v => {
              onZoom(v)
              setSliderValue(v)
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <PlusIcon style={{ width: 10, height: 10, display: 'inline-block',cursor:"pointer" }} onClick={()=> {setSliderValue(sliderValue+5)
          onZoom(sliderValue+5)}}/>
          <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm" color="white" >
            {sliderValue}
          </Text>
        </HStack>
      </Flex>

    </Box >
  );
}
