/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  useCQMediaQuery,
} from '@prasanna-transcend/code-quick-components';
import { UrgentIcon } from '../../../../constants/IconData';
import { MyAuditContext } from '../../../../providers/MyAuditProvider';
import { createColumnHelper } from '@tanstack/react-table'; //package installed using yarn -for table
import { format } from 'date-fns';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { renderActionComponent } from '../DashboardComponents';
import { useNavigate } from 'react-router-dom';
import { toTitleCase } from '../../../../utils/common.utils';
import {
  getAuditStatus,
  MY_AUDITS_ORDERING,
} from '../../../../constants/constants';
import FileViewAction from '../FileViewAction';

const columnHelper = createColumnHelper();

function ArchivedTable() {
  const auditContext = useContext(MyAuditContext);
  const { getAuditArchived } = auditContext;
  const navigate = useNavigate();
  const [ordering, setOrdering] = useState('');

  useEffect(() => {
    getAuditArchived.loadAPI();
  }, [auditContext.filterParams, getAuditArchived.showOrdering]);

  useEffect(() => {
    if (MY_AUDITS_ORDERING.includes(ordering)) {
      getAuditArchived.setParams({ ordering });
    }
  }, [ordering]);

  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  let columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'urgent_column',
        enableSorting: false,
        header: () => '',
        cell: info => {
          return (
            <HStack
              textAlign={'right'}
              justifyContent={'flex-end'}
              alignItems={'end'}
              // spacing={3}
            >
              {info.row.original?.urgent ? (
                <UrgentIcon style={{ width: 20, height: 8 }} />
              ) : (
                <Box ml={2} />
              )}
            </HStack>
          );
        },
      }),

      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'chart_id',
        cell: info => {
          return (
            <HStack>
              <Text fontSize={16} fontWeight={'bold'}>
                {info.renderValue()}
              </Text>
            </HStack>
          );
        },
      }),

      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => 'Upload Date',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }
          return (
            <Text pl={2} textAlign={'left'} fontSize={16} fontWeight={'bold'}>
              {format(new Date(cellValue), 'MM/dd/yyyy')}
            </Text>
          );
        },
      }),

      columnHelper.accessor('total_page', {
        header: () => 'Pages',
        id: 'total_pages',
        cell: info => {
          return (
            <Text ml={-2} fontSize={16} fontWeight={'bold'}>
              {info.row.original?.total_page}
            </Text>
          );
        },
      }),

      columnHelper.accessor(p => p.specialties?.name, {
        header: () => 'Specialties',
        id: 'specialty__name',
        cell: info => {
          const specialties = info.row.original.specialties?.name;

          if (!specialties) {
            return 'N/A';
          }
          return specialties === 'ent' ? 'ENT' : toTitleCase(specialties);
        },
      }),

      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => {
          return (
            <Text fontSize={16} fontWeight={'bold'}>
              {getAuditStatus(info.row.original.status)}
            </Text>
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(
            info.row.original,
            navigate,
            auditContext
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: () => '',
        enableSorting: false,
        cell: info => {
          const auditUpload = info.row.original;
          return <FileViewAction auditUpload={auditUpload} />;
        },
      }),
    ],
    [auditContext.isAuditUploadListLoading]
  );

  function handlePreviousPage() {
    auditContext.getAuditArchived.loadPrevious();
  }

  function handleNextPage() {
    auditContext.getAuditArchived.loadNext();
  }

  function handleSizeChange(size) {
    auditContext.getAuditArchived({ size });
  }

  return (
    <>
      <Box
        // pointerEvents={isUpdating ? 'none' : 'inherit'}
        overflowX={canScroll ? 'scroll' : 'inherit'}
      >
        <CQReactTable
          manualSortBy={true}
          isLoading={auditContext.isAuditUploadListLoading}
          data={auditContext.auditArchivedList}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(auditContext.getAuditArchived)}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
    </>
  );
}

export default ArchivedTable;
