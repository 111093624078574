import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    HStack,
    Center,
    Circle,
    ModalCloseButton,
    Box,
    Textarea,
} from '@chakra-ui/react';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import CommentInput from './CommentInput';
import { useContext, useEffect, useState } from 'react';
import { useBoolean } from '@prasanna-transcend/code-quick-components';
import { AuditSheetContext } from '../../../../providers/AuditSheetProvider';
import { getTaggedUser } from '../../getTaggedUsers';

const EditCommentModal = (props) => {
    const [isEditing, idState] = useBoolean(false)
    const auditSheetContext = useContext(AuditSheetContext)
    const { updateComment } = auditSheetContext
    const [message, setMessage] = useState('')
    useEffect(() => {
        if (props.selectedComment?.comment) {
            setMessage(props.selectedComment.comment)
        }
    }, [props.selectedComment])
    const notes = !props.selectedComment?.audit_sheet_rows?.length

    return <>
        <Modal
            isOpen={props.isOpen}
            size={'lg'}
            onClose={props.onClose}
            isCentered
        >
            <ModalOverlay
                bg={AppColors.modalOverlayColor}
                style={{ opacity: 0.8 }}
            />
            <ModalContent p={15} alignItems={'center'}>
                <ModalHeader
                    fontSize={'xl'}
                    color={AppColors.secondary}
                >
                    <Center fontFamily={FONT_FAMILY.baiJamurjee} textAlign={'center'} fontWeight="bold" fontSize={'xl'}>
                        Edit {notes ? 'Note' : 'Comment'}
                    </Center>
                </ModalHeader>
                <Box
                    style={{
                        zIndex: '1',
                    }}
                    position={'absolute'}
                    top={-14}
                    right={-10}
                >
                    <Circle>
                        <ModalCloseButton
                            size={'md'}
                            style={{ color: AppColors.white }}
                            backgroundColor={'#8894A6'}
                            p={3}
                            onClick={props.onClose}
                            borderRadius={50}
                        />
                    </Circle>
                </Box>
                <ModalBody width={'70%'}>
                    {props.selectedComment?.audit_sheet_rows?.length ? <CommentInput
                        message={message}
                        setMessage={setMessage}
                    /> :
                        <Textarea
                            name='notes'
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }}
                            placeholder='Edit notes....'
                        />}
                    <HStack justifyContent='center' mt={5}>
                        <Button
                            variant="outline"
                            borderRadius={'3xl'}
                            fontWeight={'normal'}
                            paddingInline={'35px'}
                            backgroundColor={AppColors.white}
                            onClick={props.onClose}
                            fontSize={'sm'}
                            borderWidth={2}
                            borderColor={AppColors.secondary}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="solid"
                            borderRadius={'3xl'}
                            color={AppColors.white}
                            paddingInline={'35px'}
                            backgroundColor={AppColors.primary}
                            _hover={{ bg: AppColors.primary }}
                            fontSize={'sm'}
                            fontWeight={'normal'}
                            isLoading={isEditing}
                            onClick={async () => {
                                idState.on()
                                const user = getTaggedUser(message)
                                const updateBody = {
                                    comment: message,
                                }
                                if (user.length) {
                                    updateBody.tagged_user = user
                                }
                                if (!props.selectedComment?.audit_sheet_rows?.length) {
                                    updateBody.notes = true
                                }
                                await updateComment(props.selectedComment.id, updateBody, props.selectedParentComment?.id)
                                idState.off()
                                props.onClose()
                            }}
                        >
                            Save
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}
export default EditCommentModal