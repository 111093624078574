import {
  DYNAMIC_AUDIT_SHEET_KEY,
  addionalAttributesKeys,
  auditedRuvColumns,
  defaultColumns,
  defaultDataProps,
  firstSetColumnKeyOrder,
  rvuColumnsOrder,
} from '../constants/constants';
import {
  AUDIT_COLUMN_HEADER,
  columnType,
  columnWidth,
  dynamicAuditColumnHeader,
  dynamicColumnRenderes,
} from '../pages/audit_sheet/constants';
import { toTitleCase } from './common.utils';

/**
 *
 * @param {Array} data
 * @returns { data with adtional_attribues }
 */

function haveAddionalAttributes(data) {
  const noAddionalAttributes = data.every(
    obj => obj.additional_attributes === null
  );

  if (noAddionalAttributes) {
    return data.map(d => {
      const tempObj = {
        ...d,
        additional_attributes: {
          provider_education: null,
          number_complexity: null,
          amount_complexity: null,
          risk_complications: null,
          audited_icd: null,
          audited_icd: null,
        },
      };
      //  delete d.old_rendering

      return tempObj;
    });
  } else {
    return data;
  }
}

function cptAndIcdColumnValidator(value, callback) {
  if (/^\d+(\.\d{1,2})?$/.test(value)) {
    callback(true);
  } else {
    callback(false);
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with srvcs Column }
 */

function isHaveSrvcsNoColumn(response) {
  const isEmptySrvcsColumn = response.every(obj => obj?.srvcs_no === null);

  if (isEmptySrvcsColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('srvcs_no_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            srvcs_no_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPT column }
 */

function isHaveBilledICDColumn(response) {
  const havebilledICD = response
    .map(obj => {
      return obj.additional_attributes.hasOwnProperty('billed_icd');
    })
    .every(Boolean);

  const isEmptyBilledCptColumn = response.every(
    obj => obj?.additional_attributes?.billed_icd === null
  );

  if (!havebilledICD) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          billed_icd: res.additional_attributes?.billed_icd
            ? res.additional_attributes?.billed_icd
            : null,
          ...res.additional_attributes,
        },
      };
    });
  } else if (isEmptyBilledCptColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res?.additional_attributes?.hasOwnProperty('billed_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_icd_2: res.additional_attributes?.billed_icd_2
              ? res.additional_attributes?.billed_icd_2
              : null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPTColumn }
 */

function isHaveBilledModifierColumn(response) {
  const isEmptyBilledModifierColumn = response.every(
    obj => obj?.billed_modifier === null
  );

  if (isEmptyBilledModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('billed_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_modifier_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns
 */
function isHAveAuditedCodeColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_code === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_cpt_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_cpt_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedcptColumn }
 */
function isHaveAuditedCPTColumn(response) {
  const haveAuditedCpt = response
    .map(obj => {
      return obj.additional_attributes.hasOwnProperty('audited_icd');
    })
    .every(Boolean);

  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.additional_attributes?.audited_icd === null
  );

  if (!haveAuditedCpt) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          audited_icd: res.additional_attributes?.audited_icd
            ? res.additional_attributes?.audited_icd
            : null,
          ...res.additional_attributes,
        },
      };
    });
  } else if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            audited_icd_2: res.additional_attributes?.audited_icd_2
              ? res.additional_attributes?.audited_icd_2
              : null,
            ...res?.additional_attributes,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedModifier }
 */
function isHaveAuditedModifierColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_modifier === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_modifier_2: null,
          },
        };
      }
    });
  }
}

export function createEmptyColumn(response) {
  const _haveAddionalAttributes = haveAddionalAttributes(response);

  // billed
  const _isHaveSrvcsNoColumn = isHaveSrvcsNoColumn(_haveAddionalAttributes);

  const _isHaveBilledCPTColumn = isHaveBilledICDColumn(_isHaveSrvcsNoColumn);

  const _isHaveBilledModifierColumn = isHaveBilledModifierColumn(
    _isHaveBilledCPTColumn
  );

  // audited
  const _isHAveAuditedCodeColumn = isHAveAuditedCodeColumn(
    _isHaveBilledModifierColumn
  );

  const _isHaveAuditedCPTColumn = isHaveAuditedCPTColumn(
    _isHAveAuditedCodeColumn
  );

  const _isHaveAuditedModifierColumn = isHaveAuditedModifierColumn(
    _isHaveAuditedCPTColumn
  );

  return _isHaveAuditedModifierColumn;
}

// prepare auditsheet headers

/**
 *
 * @param {Array} columns
 */

function sortColumns(columns) {
  return columns.sort((a, b) => {
    const aNum = parseInt(a.match(/\d+/));
    const bNum = parseInt(b.match(/\d+/));

    if (isNaN(aNum) && isNaN(bNum)) {
      return 0;
    } else if (isNaN(aNum)) {
      return -1;
    } else if (isNaN(bNum)) {
      return 1;
    }

    return aNum - bNum;
  });
}

/**
 *
 * @param {Array} headers
 */

function arrangeHeader(headers) {
  const firstSetColumns = ['Encounter #', 'Rendering', 'Enc Dt'];

  const billedIcdColumns = [];

  const billedCptColumns = [];

  const billedModifierColumns = [];

  const agreeColumns = ['RVU', '$ value', 'Response', 'Agree', 'Disagree'];

  const auditedIcdColumns = [];

  const auditedCptColumns = [];

  const auditedModifier = [];

  const auditedRvuValues = ['RVU', '$ Value', 'Notes'];

  const lastSetColumns = [
    'Provider Education',
    'Number & Complexity',
    'Amount & Complexity',
    'Mortality of PatientManagement',
  ];

  headers.forEach(h => {
    if (h) {
      if (h.match('Billed ICD')) {
        billedIcdColumns.push(h);
      } else if (h.match('Billed CPT')) {
        billedCptColumns.push(h);
      } else if (h.match('Billed Modifier')) {
        const header = h.replace(/^Billed\s+/i, '');
        billedModifierColumns.push(header);
      } else if (h.match('Audited ICD')) {
        auditedIcdColumns.push(h);
      } else if (h.match('Audited CPT')) {
        auditedCptColumns.push(h);
      } else if (h.match('Audited Modifier')) {
        const header = h.replace(/^Audited\s+/i, '');
        auditedModifier.push(header);
      }
    }
  });

  const billedColumns = [
    ...sortColumns(billedIcdColumns),
    ...sortColumns(billedCptColumns),
    ...sortColumns(billedModifierColumns),
  ];
  const auditedColumns = [
    ...sortColumns(auditedIcdColumns),
    ...sortColumns(auditedCptColumns),
    ...sortColumns(auditedModifier),
  ];

  const sortedHeaders = [
    ...firstSetColumns,
    ...billedColumns,
    ...agreeColumns,
    ...auditedColumns,
    ...auditedRvuValues,
    ...lastSetColumns,
  ];

  return sortedHeaders;
}

/**
 *
 * @param {String} key
 * @returns
 */
function prepareKey(key) {
  if (key.match('srvcs_no')) {
    const k = key.replace(/srvcs_no/i, 'billed_cpt');

    const columnKey = k.replace(/_/g, ' ').split(' ');

    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  const columnKey = key.replace(/_/g, ' ').split(' ');

  const replaceStr = columnKey.map((w, i) => {
    if (i === 0) {
      return toTitleCase(w);
    } else if (w === 'icd' || w === 'cpt') {
      return w.toUpperCase();
    } else {
      return toTitleCase(w);
    }
  });

  return replaceStr.join(' ');

  /** 
  if (key.match('audited_icd')) {
    const columnKey = key.split('_');
    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  if (key.match('billed_cpt')) {
    const columnKey = key.split('_');
    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  const unKnownKey = key.split('_');

  const _unKnownKey = unKnownKey.map(uk => {
    if(["icd",'cpt'].includes(uk)){
      return uk.toUpperCase()
    }else{
      return  toTitleCase(uk)
    }
  });


  if (_unKnownKey) {
    return _unKnownKey.join(" ");
  }
  */
}

/**
 *
 * @param {Array} sheetData
 *
 */

export function prepareAuditSheetHeader(sheetData) {
  const headers = [];

  [sheetData[0]].forEach(sd => {
    if (sd && Object.keys(sd)) {
      Object?.keys(sd).forEach(key => {
        if (
          key !== 'file_name' &&
          key !== 'id' &&
          key !== 'row_id' &&
          key !== 'chart_id' &&
          key !== 'old_rendering'
        ) {
          if (dynamicAuditColumnHeader[key]) {
            headers.push(dynamicAuditColumnHeader[key]);
          } else {
            const newKey = prepareKey(key);
            headers.push(newKey);
          }
        }
      });
    }
  });

  return arrangeHeader(headers);

  // const _headers = arrangeHeader(headers);
  // return Array.from(new Set(_headers));
}

// agree and disAgree  column validator functions
function agreeValidator(value, callback) {
  setTimeout(() => {
    if (value.toLowerCase() === 'yes' || value === '-') {
      callback(true);
    } else {
      callback(false);
    }
  }, 1000);
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareSrvcColumn(key, windowSize, view) {
  return {
    data: key,
    type: 'text',
    width: windowSize * 0.15,
    readOnly: view,
    renderer: 'customStylesRenderer',
  };
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareBilledColumn(key, windowSize, view) {
  if (key.match('billed_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('billed_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('billed_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: dynamicColumnRenderes.billed_icd,
      validator: cptAndIcdColumnValidator,
      numericFormat: {
        pattern: '0.00',
      },
    };
  } else {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  }
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareAuditedColumn(key, windowSize, view) {
  if (key.match('audited_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.18,
      readOnly: view,
      renderer: dynamicColumnRenderes.billed_icd,
      validator: cptAndIcdColumnValidator,
      numericFormat: {
        pattern: '0.00',
      },
    };
  } else if (key.match('audited_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.19,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('audited_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  }
}

function sortData(obj) {
  return Object.keys(obj)
    .sort((a, b) => {
      const hasNumberA = /\d+$/.test(a);
      const hasNumberB = /\d+$/.test(b);

      if (hasNumberA && hasNumberB) {
        const numberA = parseInt(a.match(/\d+$/)[0]);
        const numberB = parseInt(b.match(/\d+$/)[0]);
        return numberA - numberB;
      } else if (hasNumberA) {
        return 1;
      } else if (hasNumberB) {
        return -1;
      } else {
        return a.localeCompare(b);
      }
    })
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

// arragnge columns
function arrangeColumns(columnsData) {
  const firstSetColumns = [];

  const billedIctColumns = [];
  const billedCptColumns = [];
  const billedModifierColumns = [];

  const providerRvuColumns = [];

  const auditedIctColumns = [];
  const auditedCptColumns = [];
  const auditedModifierColumns = [];

  const _auditedRuvColumns = [];
  const _defaultColumns = [];

  let output = [];

  columnsData.forEach(cd => {
    if (firstSetColumnKeyOrder.includes(cd?.data)) {
      firstSetColumns.push(cd);
    } else if (rvuColumnsOrder.includes(cd?.data)) {
      providerRvuColumns.push(cd);
    } else if (auditedRuvColumns.includes(cd?.data)) {
      _auditedRuvColumns.push(cd);
    } else if (defaultColumns.includes(cd?.data)) {
      _defaultColumns.push(cd);
    } else if (cd?.data.match('srvcs_no')) {
      billedCptColumns.push(cd);
    } else if (cd?.data.match('billed_icd')) {
      billedIctColumns.push(cd);
    } else if (cd?.data.match('billed_modifier')) {
      billedModifierColumns.push(cd);
    } else if (cd?.data.match('audited_icd')) {
      auditedIctColumns.push(cd);
    } else if (cd?.data.match('audited_cpt')) {
      auditedCptColumns.push(cd);
    } else if (cd?.data.match('audited_modifier')) {
      auditedModifierColumns.push(cd);
    }
  });

  const ictCol = [...Object.values(sortData(billedIctColumns))];
  const cptCol = [...Object.values(sortData(billedCptColumns))];
  const modCol = [...Object.values(sortData(billedModifierColumns))];

  const aIctCol = [...Object.values(sortData(auditedIctColumns))];
  const aCptCol = [...Object.values(sortData(auditedCptColumns))];
  const aModCol = [...Object.values(sortData(auditedModifierColumns))];

  const tempObj = _defaultColumns.sort((a, b) => {
    const indexA = defaultColumns.indexOf(a.data);
    const indexB = defaultColumns.indexOf(b.data);
    return indexA - indexB;
  });

  const col = [
    ...firstSetColumns,
    ...ictCol,
    ...cptCol,
    ...modCol,
    ...providerRvuColumns,

    ...aIctCol,
    ...aCptCol,
    ...aModCol,
    ..._auditedRuvColumns,
    ...tempObj,
  ];

  output.push(col);

  return output.flat();
}

// sheet column
export function prepareAuditSheetColumn(
  _sheetData,
  windowSize,
  view,
  providerOptions,
  isAuditedDollarDisabled,
  isProviderDollarDisabled
) {
  const sheetData = [_sheetData[0]];

  const auditSheetColumns = [];
  sheetData.forEach(sd => {
    let columnProps = {};

    if (sd && Object.keys(sd).length) {
      Object?.keys(sd).forEach(key => {
        if (dynamicAuditColumnHeader[key]) {
          columnProps['data'] = key;
          columnProps['type'] = columnType[key];
          columnProps['width'] = windowSize * columnWidth[key];
          columnProps['readOnly'] = view;
          columnProps['renderer'] = dynamicColumnRenderes[key];

          if (key === AUDIT_COLUMN_HEADER.RENDERING) {
            const providerNames = providerOptions.map(p => p.value);
            columnProps['source'] = providerNames;
          }

          if (key === AUDIT_COLUMN_HEADER.ENC_DT) {
            columnProps['dateFormat'] = 'MM-DD-YYYY';
            columnProps['datePickerConfig'] = {
              disableDayFn(date) {
                return date > new Date();
              },
            };
          }

          if (key === AUDIT_COLUMN_HEADER.PROVIDER_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isProviderDollarDisabled;
          }

          if (key === AUDIT_COLUMN_HEADER.AUDITOR_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isAuditedDollarDisabled;
          }

          if (
            key === AUDIT_COLUMN_HEADER.AGREE ||
            key === AUDIT_COLUMN_HEADER.DISAGREE
          ) {
            columnProps['validator'] = agreeValidator;
            columnProps['renderer'] = 'agreeRenderer';
          }

          if (key === AUDIT_COLUMN_HEADER.BILLED_ICD) {
            columnProps['validator'] = cptAndIcdColumnValidator;
            columnProps['numericFormat'] = {
              pattern: '0.00',
            };
          }

          if (key === AUDIT_COLUMN_HEADER.AUDITED_ICD) {
            columnProps['validator'] = cptAndIcdColumnValidator;
            columnProps['numericFormat'] = {
              pattern: '0.00',
            };
          }

          auditSheetColumns.push(columnProps);
          columnProps = {};
        } else if (key?.match('srvcs_no')) {
          const srvcColumnProps = prepareSrvcColumn(key, windowSize, view);
          auditSheetColumns.push(srvcColumnProps);
        } else if (key?.match('billed')) {
          const billedColumnProps = prepareBilledColumn(key, windowSize, view);
          auditSheetColumns.push(billedColumnProps);
        } else if (key?.match('audited')) {
          const auditedColumnProps = prepareAuditedColumn(
            key,
            windowSize,
            view
          );
          auditSheetColumns.push(auditedColumnProps);
        }
      });
    }
  });

  const arrangedColumns = arrangeColumns(auditSheetColumns);

  return arrangedColumns;
}

// arrange data

export function arrangeData(sheetData) {
  let firstSetDatas = {};

  let billedIcddatas = {};
  let billedCptDatas = {};
  let billedModifierDatas = {};

  let agreeColumns = {};

  let auditedIcdData = {};
  let auditedCptData = {};
  let auditedModifierData = {};

  let auditedRvuColumns = {};

  let lastSetColumns = {};

  let defaultprops = {};

  const finalData = [];

  sheetData.forEach(item => {
    for (const key in item) {
      if (firstSetColumnKeyOrder.includes(key)) {
        Object.assign(firstSetDatas, { [key]: item[key] });
      } else if (rvuColumnsOrder.includes(key)) {
        Object.assign(agreeColumns, { [key]: item[key] });
      } else if (auditedRuvColumns.includes(key)) {
        Object.assign(auditedRvuColumns, { [key]: item[key] });
      } else if (defaultColumns.includes(key)) {
        Object.assign(lastSetColumns, { [key]: item[key] });
      } else if (key.match('srvcs_no')) {
        Object.assign(billedCptDatas, { [key]: item[key] });
      } else if (key.match('billed_icd')) {
        Object.assign(billedIcddatas, { [key]: item[key] });
      } else if (key.match('billed_modifier')) {
        Object.assign(billedModifierDatas, { [key]: item[key] });
      } else if (key.match('audited_icd')) {
        Object.assign(auditedIcdData, { [key]: item[key] });
      } else if (key.match('audited_cpt')) {
        Object.assign(auditedCptData, { [key]: item[key] });
      } else if (key.match('audited_modifier')) {
        Object.assign(auditedModifierData, { [key]: item[key] });
      } else if (defaultDataProps.includes(key)) {
        Object.assign(defaultprops, { [key]: item[key] });
      }
    }

    const billedColumns = {
      ...sortData(billedIcddatas),
      ...sortData(billedCptDatas),
      ...sortData(billedModifierDatas),
    };

    const auditedColumns = {
      ...sortData(auditedIcdData),
      ...sortData(auditedCptData),
      ...sortData(auditedModifierData),
    };

    const col = {
      ...firstSetDatas,
      ...billedColumns,
      ...agreeColumns,
      ...auditedColumns,
      ...auditedRvuColumns,
      ...lastSetColumns,
      ...defaultprops,
    };

    Object.assign(firstSetDatas, {});
    Object.assign(agreeColumns, {});
    Object.assign(auditedRvuColumns, {});
    Object.assign(lastSetColumns, {});

    Object.assign(billedIcddatas, {});
    Object.assign(billedCptDatas, {});
    Object.assign(billedModifierDatas, {});

    Object.assign(auditedIcdData, {});
    Object.assign(auditedCptData, {});
    Object.assign(auditedModifierData, {});
    Object.assign(defaultprops, {});

    finalData.push(col);
  });

  return finalData;
}

/**
 *
 * @param {Array} column
 */

export function findHighlightedColumn(column) {
  const highlightedIndices = [];

  column?.forEach(({ data }, i) => {
    if (data === 'provider_rvu') {
      highlightedIndices.push(i);
    } else if (data === 'provider_dollar_value') {
      highlightedIndices.push(i);
    } else if (data === 'audited_rvu') {
      highlightedIndices.push(i);
    } else if (data === 'audited_dollar_value') {
      highlightedIndices.push(i);
    }
  });

  return highlightedIndices;
}

export function findDollorColumn(column) {
  const dolloarColumnIndices = [];

  column.forEach(({ data }, i) => {
    if (data === 'provider_dollar_value') {
      dolloarColumnIndices.push(i);
    } else if (data === 'audited_dollar_value') {
      dolloarColumnIndices.push(i);
    }
  });

  return dolloarColumnIndices;
}

export function getAuditSheetKeys(columnData) {
  return columnData.map(({ data }, i) => {
    return data;
  });
}

export function findAgreeDisAgreeColumn(columnData) {
  const agreeDisAgreeIndices = [];
  columnData.forEach(({ data }, i) => {
    if (data === 'agree') {
      agreeDisAgreeIndices.push(i);
    } else if (data === 'disagree') {
      agreeDisAgreeIndices.push(i);
    }
  });

  return agreeDisAgreeIndices;
}

function sanitizeObject(payload) {
  for (const key of addionalAttributesKeys) {
    for (const k in payload) {
      if (k.match(key)) {
        delete payload[k];
      }
    }
  }

  return payload;
}

export function payloadreStructure(payload) {
  const arr = [];
  const payloadprops = payload[0];

  payload
    .map(({ data }) => data)
    .forEach(item => {
      item.forEach(i => {
        const additional_attributes = {};
        Object.keys(i).forEach(k => {
          addionalAttributesKeys.forEach(aak => {
            if (k.startsWith(aak)) {
              Object.assign(additional_attributes, { [k]: i[k] });
            }
          });
        });

        const _payload = sanitizeObject({ ...i, additional_attributes });

        const setAuditedCode = {
          ..._payload,
          audited_code: _payload['audited_icd'],
        };
        delete setAuditedCode['audited_icd'];

        arr.push(setAuditedCode);
      });
    });

  return [{ ...payloadprops, data: arr }];
}

export function creatColumnHeader(columnName) {
  const regex = /^(.*?)(\s+([0-9]+))?$/;
  const match = columnName?.match(regex);
  const prefix = match[1] || '';
  const number = match[3] ? parseInt(match[3]) : null;

  const prefixWithSpaces = prefix.trim();

  const incrementedNumber = number !== null ? number + 1 : 2;

  return `${prefixWithSpaces} ${incrementedNumber}`;
}

export function createColumnDataName(columnName) {
  const regex = /^(.*?)(_([0-9]+))?$/;
  const match = columnName.match(regex);
  const prefix = match[1] || '';
  const number = match[3] ? parseInt(match[3]) : 0;
  const incrementedNumber = number === 0 ? 2 : number + 1;

  const colName = `${prefix}_${incrementedNumber}`;

  return colName;
}

export function isColumnMatched(string, keywords) {
  for (let i = 0; i < keywords.length; i++) {
    const pattern = new RegExp(keywords[i] + '(_\\d+)?');
    const match = string.match(pattern);
    if (match) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @param {Array} columndata
 */

export function findNumericColumns(columndata) {
  const numericColumns = [
    'provider_rvu',
    'provider_dollar_value',
    'audited_rvu',
    'audited_dollar_value',
  ];

  const indices = columndata.reduce((acc, cd, i) => {
    if (numericColumns.includes(cd.data)) {
      acc.push(i);
    }
    return acc;
  }, []);

  return indices;
}

export const createEmptyTablesProps = currentUpload => {
  return [
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: [
        {
          file_name: currentUpload?.upload_id,
          chart_id: currentUpload.id,
          row_id: 1,

          id: null,

          encounter_no: null,
          rendering: null, //{ first_name: '', last_name: '', id: '' },
          enc_dt: null,

          additional_attributes: {
            billed_icd: null,

            audited_icd: null,

            provider_education: null,
            number_complexity: null,
            amount_complexity: null,
            risk_complications: null,
          },

          srvcs_no: null,
          billed_modifier: null,

          provider_rvu: null,
          provider_dollar_value: null,
          response: null,
          agree: null,
          disagree: null,

          audited_code: null,
          audited_modifier: null,

          audited_rvu: null,
          audited_dollar_value: null,
          notes: null,
        },
      ],
    },
  ];
};

// export function prepareUpdatePayload(payload) {
//   return payload.map(sheet => {
//     return {
//       ...sheet,
//       data: sheet.data.map(entry => {
//         const updatedEntry = { ...entry };
//         const additionalAttributes = {};

//         for (const key in updatedEntry) {
//           if (!DYNAMIC_AUDIT_SHEET_KEY.includes(key)) {
//             additionalAttributes[key] = updatedEntry[key];
//             delete updatedEntry[key];
//           } else if (key === 'audited_cpt') {
//             updatedEntry.audited_code = updatedEntry.audited_cpt;
//             delete updatedEntry.audited_cpt;
//           }
//         }

//         if (Object.keys(additionalAttributes).length > 0) {
//           updatedEntry.additional_attributes = additionalAttributes;
//         }

//         return updatedEntry;
//       }),
//     };
//   });
// }

export function prepareUpdatePayload(payload) {
  return payload.map(sheet => {
    const payloadData = sheet?.data?.map(entry => {
      const updatedEntry = { ...entry };
      const additionalAttributes = {};

      for (const key in updatedEntry) {
        if (!DYNAMIC_AUDIT_SHEET_KEY.includes(key)) {
          additionalAttributes[key] = updatedEntry[key];
          delete updatedEntry[key];
        } else if (key === 'audited_cpt') {
          updatedEntry.audited_code = updatedEntry.audited_cpt;
          delete updatedEntry.audited_cpt;
        }
      }

      if (Object.keys(additionalAttributes).length > 0) {
        updatedEntry.additional_attributes = additionalAttributes;
      }

      return updatedEntry;
    });

    return {
      ...sheet,
      data: addMissingKeysWithNull(payloadData),
    };
  });
}

function addMissingKeysWithNull(arr) {
  const dynamicKey = 'additional_attributes';

  const allKeys = new Set();
  const allNestedKeys = new Set();

  //  getting all keys
  arr?.forEach(obj => {
    Object.keys(obj).forEach(key => {
      allKeys.add(key);
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.keys(obj[key]).forEach(nestedKey => {
          allNestedKeys.add(nestedKey);
        });
      }
    });
  });

  const dynamicKeyValues = new Map();
  // getting addionalattributes
  arr?.forEach(obj => {
    if (typeof obj[dynamicKey] === 'object' && obj[dynamicKey] !== null) {
      dynamicKeyValues.set(obj[dynamicKey], true);
    }
  });

  const unionKeys = new Set();

  arr?.forEach(obj => {
    if (dynamicKey in obj) {
      Object.keys(obj[dynamicKey]).forEach(key => {
        unionKeys.add(key);
      });
    }
  });

  arr?.forEach(obj => {
    if (dynamicKey in obj) {
      const dynamicKeyValue = { ...obj[dynamicKey] };
      unionKeys.forEach(nestedKey => {
        if (typeof dynamicKeyValue[nestedKey] === 'undefined') {
          dynamicKeyValue[nestedKey] = null;
        }
      });
      obj[dynamicKey] = dynamicKeyValue;
    } else {
      const nullObj = {};
      unionKeys.forEach(nestedKey => {
        nullObj[nestedKey] = null;
      });
      obj[dynamicKey] = nullObj;
    }

    allKeys?.forEach(key => {
      if (!(key in obj) || obj[key] === null) {
        obj[key] = null;
      }
    });

    dynamicKeyValues?.forEach((value, key) => {
      if (value && key !== obj[dynamicKey]) {
        Object.keys(key).forEach(k => {
          if (typeof obj[dynamicKey][k] === 'undefined') {
            obj[dynamicKey][k] = key[k];
          }
        });
      }
    });
  });

  return arr;
}

/**
 *
 * @param {Array} sheet
 */
export const isEmptySheet = sheet => {
  return sheet.every(obj => Object.keys(obj).length === 1);
};
export const emptyDataPrefilledObject = currentUpload => {
  return {
    file_name: currentUpload?.upload_id,
    chart_id: currentUpload.id,
    row_id: 1,
    id: null,
    encounter_no: null,
    rendering: { first_name: '', last_name: '', id: '' },
    enc_dt: null,
    billed_icd: null,
    audited_icd: null,
    provider_education: null,
    number_complexity: null,
    amount_complexity: null,
    risk_complications: null,
    srvcs_no: null,
    billed_modifier: null,
    provider_rvu: null,
    provider_dollar_value: null,
    response: null,
    agree: null,
    disagree: null,
    audited_cpt: null,
    audited_modifier: null,
    audited_rvu: null,
    audited_dollar_value: null,
    notes: null,
  };
};
