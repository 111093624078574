import { REQUIRED_FIELDS } from "../../../constants/constants";

const submitRule = (sheets) => {
    return sheets.some(currentSheet => {
        const canSubmit = currentSheet?.data?.length ? currentSheet.data.every(element => {
            const headers = Object.keys(element)
            const data = REQUIRED_FIELDS.every(key => {
                if (headers.includes(key) && element[key] !== null && element[key] !== "") {
                    return true
                }
                return false
            })
            return data
        }) : true;
        return !canSubmit
    })

}
export { submitRule }
