import {
  useBoolean,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import React, { createContext, useState } from 'react';
import auditSheetRepository from '../repositories/AuditSheetRepository';
import notificationRepository from '../repositories/NotificationRepository';

export const NotificationsContext = createContext({
  // async func
  getNotifications: async () => {},
  markRead: async notificationId => {},
  loadNewNotification: () => {},

  // state
  notifications: [],
  unReadNotification: 0,
  notificationCount: 0,

  // boolean
  isFetchingNotifications: false,
  getAuditUpload: async () => {},
  setNotifications: () => {},
  setUnReadNotification: () => {},
  setNotificationCount: () => {},
});

function NotificationProvider(props) {
  const toast = useToastr();

  const [notifications, setNotifications] = useState([]);
  const [unReadNotification, setUnReadNotification] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);

  const [isFetchingNotifications, ifnState] = useBoolean(false);

  const getNotifications = async currentPage => {
    try {
      ifnState.on();
      const response = await notificationRepository.getNotifications(
        currentPage
      );
      setNotifications(response.results);
      setUnReadNotification(response?.unread_count);
      setNotificationCount(response.count);
      return response;
    } catch (err) {
      toast.showError({
        description: `${err.toString()}`,
      });
    } finally {
      ifnState.off();
    }
  };

  const markRead = async notificationId => {
    try {
      const response = await notificationRepository.markRead(notificationId);
      setUnReadNotification(unReadNotification - 1);
      setNotifications(prev => [...prev, response]);
      return response;
    } catch (err) {
      toast.showError({
        description: `${err.toString()}`,
      });
    }
  };

  async function getAuditUpload(uploadId) {
    try {
      await auditSheetRepository.getAuditUpload(uploadId);
      return true;
    } catch (err) {
      return false;
    }
  }

  const loadNewNotification = async () => {
    try {
      const newNotificationPageNumber = 1;
      //  ifnState.on();
      const response = await notificationRepository.getNotifications(
        newNotificationPageNumber
      );

      return response;
    } catch (err) {
      toast.showError({
        description: `${err.toString()}`,
      });
    } finally {
      //  ifnState.off();
    }
  };

  const mContext = {
    getNotifications,
    markRead,

    notifications,
    unReadNotification,
    notificationCount,

    isFetchingNotifications,
    getAuditUpload,
    loadNewNotification,
    setNotifications,
    setUnReadNotification,
    setNotificationCount,
  };

  return (
    <NotificationsContext.Provider value={mContext}>
      {props.children}
    </NotificationsContext.Provider>
  );
}

export default NotificationProvider;
